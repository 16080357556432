import React from 'react'
import { useTranslate } from '@chordcommerce/gatsby-theme-autonomy'
import { Metadata, Page } from '~/components/Layout'
// import Layout from '~/components/Layout'
// import Metadata from '~/components/Metadata'
import OrderConfirmationPage from '~/components/Order/Confirmation/Page'

const OrderConfirmation = ({ number, id }) => {
  const translate = useTranslate()

  return (
    <Page>
      <Metadata title={translate('confirmation.page_title')} />
      <OrderConfirmationPage orderNumber={number} checkoutSessionId={id} />
    </Page>
  )
}

export default OrderConfirmation
